import { useContext, useCallback } from 'react'

import { AdminContext } from 'contexts'
import { Admin } from 'types'
import { setAuthHeader } from 'utils'

const useLogIn = () => {
  const { setAdmin } = useContext(AdminContext)

  return [
    useCallback(
      (token: string, admin: Admin) => {
        setAuthHeader(token)
        setAdmin(admin)
        localStorage.setItem('token', token)
        localStorage.setItem('admin', JSON.stringify(admin))
      },
      [setAdmin],
    ),
  ]
}

export { useLogIn }

import React from 'react'
import MuiSnackbar from '@material-ui/core/Snackbar'
import Slide from '@material-ui/core/Slide'
import { TransitionProps } from '@material-ui/core/transitions'

interface SnackbarProps {
  message: React.ReactNode
  isOpen: boolean
  handleClose: () => void
}

const TransitionUp: React.FC<TransitionProps> = props => {
  return <Slide {...props} direction="up" />
}

const Snackbar: React.FC<SnackbarProps> = ({ message, isOpen, handleClose }) => {
  return (
    <MuiSnackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      message={message}
      TransitionComponent={TransitionUp}
    />
  )
}

export { Snackbar }

import { AxiosRequestConfig } from 'axios'

import { CreateVisitData } from './types'

const createVisit = ({
  userId,
  prizes,
  coupons,
  adminId,
}: CreateVisitData): AxiosRequestConfig => ({
  url: `/users/${userId}/visit`,
  method: 'POST',
  data: {
    prizes: prizes?.length ? prizes : undefined,
    coupons: coupons?.length ? coupons : undefined,
    adminId: adminId || undefined,
  },
})

export { createVisit }

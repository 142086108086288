import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'styles'

// TODO: use maxWidth to set the QR width
const QR_WIDTH = window.innerWidth < 700 ? window.innerWidth : 700

const useStyles = makeStyles({
  container: {
    background: COLORS.GREY_DARK,
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: 60,
  },
  qrView: {
    width: QR_WIDTH,
    height: QR_WIDTH,
    position: 'relative',
  },
  qrReader: {
    width: QR_WIDTH,
    position: 'absolute',
  },
  overlay: {
    position: 'absolute',
    width: QR_WIDTH,
    height: QR_WIDTH,
    top: 0,
    left: 0,
    zIndex: 1,
    boxSizing: 'border-box',
    border: '50px solid rgba(0, 0, 0, 0.5)',
  },
  topLeftCorner: {
    position: 'absolute',
    top: -3,
    left: -3,
    zIndex: 1,
  },
  topRightCorner: {
    position: 'absolute',
    top: -3,
    right: -3,
    zIndex: 1,
  },
  bottomLeftCorner: {
    position: 'absolute',
    bottom: -3,
    left: -3,
    zIndex: 1,
  },
  bottomRightCorner: {
    position: 'absolute',
    bottom: -3,
    right: -3,
    zIndex: 1,
  },
  checkLogo: {
    position: 'absolute',
    zIndex: 1,
  },
})

export { useStyles }

import { makeStyles } from '@material-ui/core/styles'

import { FONTS, COLORS } from 'styles'

const useStyles = makeStyles({
  logo: {
    marginBottom: 15,
  },
  modalTitle: {
    color: COLORS.GREEN_LIGHT,
    fontFamily: FONTS.POPPINS_BOLD,
    fontSize: '1.3rem',
    textAlign: 'center',
  },
})

export { useStyles }

import { useEffect, useState } from 'react'

import { snackbar } from 'components'

import { Errors, Rules, Rule, WithSnackProps } from './types'

const useCheckFormErrors = <D extends object>(data: D, rules: Rules<D>) => {
  const [errors, setErrors] = useState<Errors | null>(null)
  const [hasErrors, setHasErrors] = useState(false)
  const [isAnyFieldEmpty, setIsAnyFieldEmpty] = useState(false)
  const initialErrors: Errors = {}
  if (errors === null) {
    Object.keys(rules).forEach(fieldName => {
      initialErrors[fieldName] = {
        hasError: false,
        message: '',
      }
    })
  }

  useEffect(() => {
    const newErrors: Errors = {}
    let anyFieldEmpty = false
    let containsErrors = false
    Object.entries(rules).forEach(([fieldName, fieldRules]) => {
      newErrors[fieldName] = {
        hasError: false,
        message: '',
      }

      if (fieldName in data) {
        const fieldValue = data[fieldName as keyof D]
        if (!fieldValue) {
          anyFieldEmpty = true
          return
        }

        const rulesArray = fieldRules as Rule[]

        rulesArray.forEach(rule => {
          const isValid = rule.validate(fieldValue, data)
          if (!isValid) {
            containsErrors = true
            newErrors[fieldName].hasError = true
            newErrors[fieldName].message = rule.message
          }
        })
      } else throw new Error(`Field "${fieldName}" (from rules) does not exist in data.`)
    })
    setErrors(newErrors)
    setIsAnyFieldEmpty(anyFieldEmpty)
    setHasErrors(containsErrors)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(data)])
  return { errors: errors || initialErrors, isAnyFieldEmpty, hasErrors }
}

const withSnackbar: (props: WithSnackProps) => () => boolean = ({
  errors,
  isAnyFieldEmpty,
  hasErrors,
}) => {
  const checkErrors = (): boolean => {
    if (isAnyFieldEmpty) {
      snackbar.show('Llená todos los campos primero.')
      return false
    }
    if (hasErrors) {
      const { message } = Object.values(errors).filter(validation => validation.hasError)[0]
      snackbar.show(message)
      return false
    }
    return true
  }
  return checkErrors
}

export { useCheckFormErrors, withSnackbar }

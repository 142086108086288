import { makeStyles } from '@material-ui/core/styles'

import { COLORS, FONTS } from 'styles'

const useStyles = makeStyles({
  listItem: {
    paddingLeft: 5,
  },
  itemIcon: {
    width: 30,
    height: 30,
    margin: '0px 10px',
  },
  itemText: {
    fontFamily: FONTS.POPPINS_SEMI_BOLD,
    fontSize: '0.8rem',
    color: COLORS.GREY_PLACEHOLDER,
  },
  listItemIcon: {
    minWidth: 0,
  },
})

export { useStyles }

import React from 'react'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import { Modal as MUIModal, Grid, IconButton } from '@material-ui/core'

import { useStyles } from './styles'

interface PosModalProps {
  open: boolean
  onClose: () => void
}

const Modal: React.FC<PosModalProps> = ({ open, onClose, children }) => {
  const classes = useStyles()
  return (
    <MUIModal open={open} onClose={onClose} className={classes.backdrop}>
      <div className={classes.modal}>
        <Grid container justify="flex-end">
          <IconButton onClick={onClose}>
            <CloseRoundedIcon />
          </IconButton>
        </Grid>
        {children}
      </div>
    </MUIModal>
  )
}

export { Modal }

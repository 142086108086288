import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'

import { AdminContext } from 'contexts'

const ProtectedRoutes: React.FC = ({ children }) => {
  const { admin } = useContext(AdminContext)

  if (!admin) return <Redirect to="/login" />

  return <>{children}</>
}

export { ProtectedRoutes }

import React from 'react'

import { ReactComponent as CheckedIcon } from 'assets/icons/checked-item.svg'
import { ReactComponent as UncheckedIcon } from 'assets/icons/unchecked-item.svg'
import { COLORS } from 'styles'

interface CheckboxProps {
  selected: boolean
  onSelect: () => void
}

const Checkbox: React.FC<CheckboxProps> = ({ selected, onSelect }) =>
  selected ? (
    <CheckedIcon onClick={onSelect} width={25} height={25} color={COLORS.GREEN_LIGHT} />
  ) : (
    <UncheckedIcon onClick={onSelect} width={25} height={25} />
  )

export { Checkbox }

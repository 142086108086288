import { useContext, useCallback } from 'react'

import { AdminContext } from 'contexts'
import { removeAuthHeader } from 'utils/axios'

const useLogOut = () => {
  const { setAdmin } = useContext(AdminContext)

  return [
    useCallback(() => {
      removeAuthHeader()
      setAdmin(undefined)
      localStorage.clear()
    }, [setAdmin]),
  ]
}

export { useLogOut }

import { makeStyles } from '@material-ui/core/styles'

import { COLORS } from 'styles'

const useStyles = makeStyles({
  modal: {
    backgroundColor: COLORS.WHITE,
    position: 'absolute',
    width: 290,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 5,
    boxShadow: 'none',
    overflow: 'hidden',
    outline: 'none',
    paddingBottom: 40,
  },
  backdrop: {
    backgroundColor: COLORS.GREY_BACKDROP,
  },
})

export { useStyles }

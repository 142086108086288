import React from 'react'

import { Snackbar } from '../Snackbar'

interface SnackbarContainerState {
  isOpen: boolean
  message: string | undefined
}

class SnackbarContainer extends React.Component<{}, SnackbarContainerState> {
  constructor(props: {}) {
    super(props)

    this.state = {
      isOpen: false,
      message: undefined,
    }
  }

  closeSnackbar = () => {
    this.setState({ isOpen: false })
  }

  showSnackbar = (message: string) => {
    this.setState({ isOpen: true, message })
  }

  render() {
    const { isOpen, message } = this.state
    return <Snackbar isOpen={isOpen} handleClose={this.closeSnackbar} message={message} />
  }
}

export { SnackbarContainer }

import { makeStyles } from '@material-ui/core/styles'

import { FONTS, COLORS } from 'styles'

const useStyles = makeStyles({
  errorLogo: {
    marginBottom: 15,
  },
  closeButton: {
    right: 0,
  },
  modalTitle: {
    color: COLORS.RED,
    fontFamily: FONTS.POPPINS_BOLD,
    fontSize: '1rem',
    marginLeft: 20,
  },
  descriptionText: {
    color: COLORS.GREY_MODAL_TEXT,
    fontFamily: FONTS.POPPINS_MEDIUM,
    fontSize: '0.8rem',
    marginLeft: 20,
  },
  listText: {
    color: COLORS.GREY_MODAL_TEXT,
    fontFamily: FONTS.POPPINS_BOLD,
    fontSize: '0.8rem',
    marginLeft: 20,
  },
  bottomText: {
    marginTop: 15,
    marginBottom: 10,
    color: COLORS.GREY_MODAL_TEXT,
    fontFamily: FONTS.POPPINS_MEDIUM,
    fontSize: '0.7rem',
    alignSelf: 'center',
    textAlign: 'center',
  },
  textGrid: {
    marginLeft: 30,
  },
})

export { useStyles }

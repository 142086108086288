import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useHistory } from 'react-router'

import { Modal } from 'components'
import { ReactComponent as ErrorLogo } from 'assets/icons/error.svg'

import { useStyles } from './styles'

interface ErrorModalProps {
  open: boolean
}

const ErrorModal: React.FC<ErrorModalProps> = ({ open }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Modal open={open} onClose={() => history.push('home')}>
      <Grid container direction="column" spacing={2}>
        <Grid item container justify="center" alignContent="center">
          <ErrorLogo width={50} height={50} className={classes.errorLogo} />
        </Grid>
        <Grid item container direction="column" spacing={1}>
          <Grid item>
            <Typography className={classes.modalTitle}>No pudimos leer el QR :(</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.descriptionText}>Pedile al cliente que:</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.listText}>
              1. Cierre la app y la vuelva a abrir
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.listText}>2. Haga swipe en su QR</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.descriptionText}>Volvé a escanear</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.bottomText}>
              (Si no lo lee, intentar ingresando código alfanumérico)
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  )
}

export { ErrorModal }

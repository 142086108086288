import { Divider, ListItem, ListItemText, Typography, ListItemIcon } from '@material-ui/core'
import React from 'react'

import { useStyles } from './styles'
import { Checkbox } from '../Checkbox'

interface RowProps {
  iconUrl: string
  title: string
  onSelect: () => void
  selected: boolean
}

const Row: React.FC<RowProps> = ({ iconUrl, title, onSelect, selected }) => {
  const classes = useStyles()
  return (
    <div>
      <Divider />
      <ListItem onClick={onSelect} className={classes.listItem}>
        <ListItemIcon className={classes.listItemIcon}>
          <Checkbox selected={selected} onSelect={onSelect} />
        </ListItemIcon>
        <ListItemIcon className={classes.listItemIcon}>
          <img src={iconUrl} className={classes.itemIcon} alt="" />
        </ListItemIcon>
        <ListItemText primary={<Typography className={classes.itemText}>{title}</Typography>} />
      </ListItem>
    </div>
  )
}

export { Row }

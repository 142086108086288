const GENERIC_ERROR_MESSAGE = 'Ocurrió un error. Intentalo de nuevo'
const QR_NOT_FOUND_MESSAGE = 'El QR no pertenece a ningun usuario existente'
const RESOURCE_NOT_FOUND_MESSAGE = 'ResourceNotFound'
const VISIT_DURATION_ERROR_MESSAGE = 'Error, intente generar una visita luego de 30 minutos.'

export const ERRORS = {
  GENERIC_ERROR_MESSAGE,
  QR_NOT_FOUND_MESSAGE,
  RESOURCE_NOT_FOUND_MESSAGE,
  VISIT_DURATION_ERROR_MESSAGE,
}
